import React from 'react'

import { TransitionGroup, Transition } from 'react-transition-group'

const styles = {
	entering: {
		opacity: 0,
	},
	entered: {
		transition: 'opacity 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 150ms',
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
}

const PageTransition = ({ children, location }) => {
	if (!location.state?.noTransition) {
		return (
			<TransitionGroup component="main" appear={false}>
				<Transition key={location.key} timeout={0}>
					{(status) => <div style={{ ...styles[status] }}>{children}</div>}
				</Transition>
			</TransitionGroup>
		)
	} else {
		return <div>{children}</div>
	}
}

export default PageTransition
