import React from 'react'

import { HeaderContext } from './context'

const Provider = ({ children }) => {
	const [hasBack, setHasBack] = React.useState(false)

	return <HeaderContext.Provider value={{ hasBack, setHasBack }}>{children}</HeaderContext.Provider>
}

export default Provider
