import React, { useEffect, useRef } from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import $ from 'jquery'

import Link from 'src/components/link/index'

import styles from './nav.module.scss'

const LangLink = ({ lang, children }) => {
	const { originalPath, changeLanguage } = useI18next()

	const path = lang === 'ja' ? originalPath : `/${lang}${originalPath}`

	const click = (e) => {
		e.preventDefault()
		changeLanguage(lang)
	}

	return (
		<a href={path} hrefLang={lang} onClick={click}>
			{children}
		</a>
	)
}

const Nav = ({ open }) => {
	const { language, t } = useI18next()

	const timer = useRef()
	const div = useRef()

	useEffect(() => {
		clearTimeout(timer.current)

		if (open) {
			$(`.${styles.nav}`).get(0).scrollTop = 0
			document.documentElement.style.overflow = ''
			$('body, .fixed').css({ paddingRight: window.innerWidth - document.body.clientWidth })
			document.documentElement.style.overflow = 'hidden'
		} else {
			timer.current = setTimeout(() => {
				$('body, .fixed').css({ paddingRight: '' })
				document.documentElement.style.overflow = ''
			}, 600)
		}

		// アニメーション
		if (open) {
			$(div.current)
				.find(`.${styles.mainNav} li, .${styles.subNav}, .${styles.lang}, .${styles.copyright}`)
				.each((i, el) => {
					el.style.cssText = ``

					setTimeout(() => {
						el.style.transition = `opacity 800ms ease-in, transform 1000ms ease-out`
						el.style.opacity = 1
						el.style.transform = `translate3d(0, 0, 0)`
					}, 100 + i * 50)
				})
		} else {
			$(div.current)
				.find(`.${styles.mainNav} li, .${styles.subNav}, .${styles.lang}, .${styles.copyright}`)
				.each((i, el) => {
					el.style.transition = `opacity 500ms ease-in ${i * 10}ms, transform 1000ms ease-out 100ms`
					el.style.opacity = ``
				})
		}
	}, [open])

	return (
		<div className={styles.nav} data-opened={open}>
			<div ref={div}>
				<ul className={styles.mainNav}>
					<li>
						<Link to="/#loquat-tour">
							<span>LOQUAT Tour</span>
						</Link>
					</li>
					<li>
						<Link to="/#stay">
							<span>Stay</span>
						</Link>
					</li>
					<li>
						<Link to="/#table-and-glass">
							<span>Table &amp; Glass</span>
						</Link>
					</li>
					<li>
						<Link to="/#gelato-and-bake">
							<span>Gelato &amp; Bake</span>
						</Link>
					</li>
					<li>
						<Link to="/#spa">
							<span>Spa</span>
						</Link>
					</li>
					<li>
						<Link to="/#bicycle-around">
							<span>Bicycle around</span>
						</Link>
					</li>
					<li>
						<Link to="/#store">
							<span>Toi Specialty Store</span>
						</Link>
					</li>
					<li>
						<Link to="/news/">
							<span>News</span>
						</Link>
					</li>
					<li>
						<Link to="/contact/">
							<span>Contact</span>
						</Link>
					</li>
				</ul>
				<ul className={styles.subNav}>
					<li>
						<Link to="https://catalyst-tokyo.co.jp">{t(`common:nav.company`)}</Link>
					</li>
					<li>
						<Link to={`/pdf/${language}/privacy_policy.pdf`}>{t(`common:nav.privacy`)}</Link>
					</li>
				</ul>
				<ul className={styles.lang}>
					<li>
						<LangLink lang="ja">日本語</LangLink>
					</li>
					<li>
						<LangLink lang="en">English</LangLink>
					</li>
					<li>
						<LangLink lang="zh-CN">中文 – 简体</LangLink>
					</li>
					<li>
						<LangLink lang="zh-TW">中文 – 繁體</LangLink>
					</li>
				</ul>
				<span className={styles.copyright}>© 2021 Loquat. All Rights Reserved</span>
			</div>
		</div>
	)
}

export default Nav
