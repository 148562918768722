import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'

const CustomLink = ({ to, state, children, ...props }) => {
	const { language, originalPath } = useI18next()

	// const handleClick = (e) => {
	// 	if (/(.*)#(.*)/.test(to) && originalPath === RegExp.$1) {
	// 		e.preventDefault()
	// 		window.location.hash = RegExp.$2
	// 	} else {
	// 		setTimeout(() => {
	// 			navigate(to, { state })
	// 		}, 0)
	// 	}

	// 	if (onClick) {
	// 		onClick()
	// 	}
	// }

	// TELリンク
	if (/^tel:/.test(to)) {
		return (
			<a href={to} {...props}>
				{children}
			</a>
		)
	}

	// PDFリンク
	if (/\.pdf/.test(to)) {
		return (
			<a href={to} target="_blank" rel="noopener noreferrer" {...props}>
				{children}
			</a>
		)
	}

	// 外部リンク
	if (/^http/.test(to)) {
		return (
			<a href={to} target="_blank" rel="noopener noreferrer" {...props}>
				{children}
			</a>
		)
	}

	// ページ内アンカーリンク
	if (/(.*)#(.*)/.test(to) && originalPath === RegExp.$1) {
		return (
			<a href={language === 'ja' ? to : `/${language}${to}`} {...props}>
				{children}
			</a>
		)
	}

	// 内部リンク
	return (
		<Link to={to} {...props}>
			{children}
		</Link>
	)
}

export default CustomLink
