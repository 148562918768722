import React from 'react'

import { useI18next } from 'gatsby-plugin-react-i18next'

import Link from 'src/components/link/index'

import styles from './index.module.scss'

const Footer = () => {
	const { language, t } = useI18next()

	return (
		<footer className={styles.footer}>
			{/* <div className={styles.upper}>
				<ul>
					<li><Link to="tel:0558-79-3170">Loquatの空室確認 / 宿泊予約</Link></li>
					<li><Link to="">オンラインストア</Link></li>
				</ul>
			</div> */}
			<div className={styles.lower}>
				<ul className={styles.nav}>
					<li>
						<Link to="/#loquat-tour">LOQUAT Tour</Link>
					</li>
					<li>
						<Link to="/#stay">Stay</Link>
					</li>
					<li>
						<Link to="/#table-and-glass">Table &amp; Glass</Link>
					</li>
					<li>
						<Link to="/#gelato-and-bake">Gelato &amp; Bake</Link>
					</li>
					<li>
						<Link to="/#spa">Spa</Link>
					</li>
					<li>
						<Link to="/#bicycle-around">Bicycle around</Link>
					</li>
					<li>
						<Link to="/#store">Toi Specialty Store</Link>
					</li>
					<li>
						<Link to="/news/">News</Link>
					</li>
					<li>
						<Link to="/contact/">Contact</Link>
					</li>
				</ul>
				<ul className={styles.nav}>
					<li>
						<Link to="https://catalyst-tokyo.co.jp">{t(`common:nav.company`)}</Link>
					</li>
					<li>
						<Link to={`/pdf/${language}/privacy_policy.pdf`}>{t(`common:nav.privacy`)}</Link>
					</li>
				</ul>
				<div className={styles.address}>365 Toi Izu, Shizuoka 410-3302 JAPAN</div>
				<div className={styles.tel}>
					<a href="tel:0558-79-3170">TEL 0558-79-3170</a>
				</div>
				<div className={styles.sns}>
					<ul>
						<li>
							<Link to="https://www.facebook.com/loquatnishiizu">facebook</Link>
						</li>
						<li>
							<Link to="https://twitter.com/loquat_nishiizu?s=20">twitter</Link>
						</li>
						<li>
							<Link to="https://www.instagram.com/loquat_nishiizu/">instagram</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer
