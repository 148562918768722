import React, { useContext, useRef, useEffect } from 'react'

import { ColsContext } from 'src/hooks/cols/context'

import Transition from '../transition/index'
import Header from '../header/index'
import Footer from '../footer/index'

import styles from './index.module.scss'

import './reset.scss'

const Layout = ({ children, location }) => {
	const { setCols } = useContext(ColsContext)

	const wrapper = useRef(null)

	useEffect(() => {
		const computedStyle = window.getComputedStyle(wrapper.current, '::before')

		let cols = null

		function resize() {
			const _cols = computedStyle.getPropertyValue('font-family')

			if (cols !== _cols) {
				cols = _cols
				setCols(_cols)
			}
		}

		resize()

		window.addEventListener('resize', resize)

		return () => {
			window.removeEventListener('resize', resize)
		}
	}, [setCols])

	return (
		<div ref={wrapper} className={styles.checker}>
			<Header />

			<Transition location={location}>{children}</Transition>

			<Footer />
		</div>
	)
}

export default Layout
