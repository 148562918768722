/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react')

const ColsProvider = require('./src/hooks/cols/provider').default
const HeaderProvider = require('./src/hooks/header/provider').default

exports.wrapRootElement = ({ element }) => {
	return (
		<ColsProvider>
			<HeaderProvider>{element}</HeaderProvider>
		</ColsProvider>
	)
}

// exports.shouldUpdateScroll = ({ routerProps }) => {
//     if (routerProps.location.action === 'PUSH' && routerProps.location.state?.noTransition) {
//         return false
//     } else {
//         return true
//     }
// }
