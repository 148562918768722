import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'

import { useLocation } from '@reach/router'

import $ from 'jquery'

import Link from 'src/components/link/index'
import Nav from './nav'
import styles from './index.module.scss'

const Header = () => {
	const { t } = useTranslation()

	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [status, setStatus] = useState(``)

	const { pathname } = useLocation()

	useEffect(() => {
		setOpen(false)
	}, [pathname])

	useEffect(() => {
		if (open) {
			setStatus('opening')
		} else {
			setStatus('')
		}
	}, [open])

	useEffect(() => {
		$(ref.current).on('click', 'a', (e) => {
			setOpen(false)
		})
	}, [])

	return (
		<div ref={ref} className={styles.wrapper}>
			<Nav open={open} />

			<header className={'fixed ' + styles.header}>
				<div className={styles.bar}>
					<div className={styles.title}>
						<Link to="/#">
							<img src="/img/logo.svg" alt="LOQUAT 西伊豆" />
						</Link>
					</div>

					<div className={styles.right}>
						<ul className={styles.sns}>
							<li>
								<Link to="https://www.facebook.com/loquatnishiizu">facebook</Link>
							</li>
							<li>
								<Link to="https://twitter.com/loquat_nishiizu?s=20">twitter</Link>
							</li>
							<li>
								<Link to="https://www.instagram.com/loquat_nishiizu/">instagram</Link>
							</li>
						</ul>

						<div className={styles.reserve}>
							<Link className={styles.reserve1} to={t(`common:reserve.url`)}>
								{t(`common:reserve.text`)}
							</Link>
							<Link className={styles.reserve2} to={t(`common:meal.url`)}>
								{t(`common:meal.text`)}
							</Link>
						</div>

						<button className={styles.button} onClick={() => setOpen(!open)} aria-label="OPEN MENU" data-animation-status={status}>
							<div>
								<span className={styles.bar1}></span>
								<span className={styles.bar2}></span>
								<span className={styles.bar3}></span>
								<span className={styles.bar4}></span>
								<span className={styles.bar5}></span>
							</div>
						</button>
					</div>
				</div>
			</header>
		</div>
	)
}

export default Header
