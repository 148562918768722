import React from 'react'

import { ColsContext } from './context'

const Provider = ({ children }) => {
	const [cols, setCols] = React.useState(null)

	return <ColsContext.Provider value={{ cols, setCols }}>{children}</ColsContext.Provider>
}

export default Provider
